import { graphql } from 'gatsby';
import React from 'react';
import { PageQuery } from '../../graphql-generated';
import { PageProps } from '../../types/pageProps';
import { ListPage } from '../listPage';
import { StandardPage } from '../standardPage';
import './page.scss';

const Page: React.FC<PageProps<PageQuery>> = (props) => {
  const page = props?.data?.page;

  if (!page) return <div>Failed.</div>;

  switch (page.template?.templateName) {
    case 'List Page':
      return <ListPage data={props?.data} fromCategoryOrPage />;
  }

  return <StandardPage {...props} />;
};

export const query = graphql`
  query PageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      isFrontPage
      template {
        templateName
      }
      contentAST {
        ...ContentAST
      }
      date
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 80) {
                presentationWidth
                presentationHeight
                ...TechImageSharpFluid_withWebp
              }
            }
          }
          caption
          altText
          title
        }
      }

      acfPageLayout {
        sidebar {
          content {
            ...PageSidebarContent
          }
        }
        mainSection {
          content {
            ...PageMainSectionContent
          }
        }
        bottomSection {
          content {
            ...PageBottomSectionContent
          }
        }
        lowestSection {
          content {
            ...PageLowestSectionContent
          }
        }
        latestNews {
          showLatestNewsAtTheTop
        }
      }
    }
  }
`;

export default Page;
