import Img from 'gatsby-image';
import React from 'react';
import { Separator, SeparatorColor } from '../components/atoms/separator';
import { ASTRenderer } from '../components/molecules/astRenderer';
import { Seo } from '../components/molecules/seo';
import { PageQuery } from '../graphql-generated';
import { PageProps } from '../types/pageProps';
import { isNotNull } from '../utils/isNotNull';
import { createForPage } from '../utils/seo/createForPage';

export const StandardPage: React.FC<PageProps<PageQuery>> = ({ data }) => {
  const page = data.page!;
  const contentImages = page.contentAST?.images?.filter(isNotNull) || [];
  const image = page.featuredImage?.node?.localFile?.childImageSharp?.fluid;

  return (
    <>
      <Seo {...createForPage(data)} />
      <div className="container main-wrapper">
        <div className="main-wrapper__col-1">
          <h1>{page.title}</h1>
          <Separator className="top-separator" color={SeparatorColor.Dark} />
          {image && (
            <Img
              fluid={image as any}
              alt={page.featuredImage?.node?.altText ?? ''}
            />
          )}
          {page.contentAST && (
            <ASTRenderer ast={page.contentAST.ast} images={contentImages} />
          )}
        </div>
      </div>
    </>
  );
};
